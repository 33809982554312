import React from "react";

const AuctionsSecondComp = () => {
  return (
    <>
      <section className="auction-secnd-comp" id="history">
          <div className="auctions-detls-info-bx">
            <p>
              Auctions have a long history and are one of the oldest surviving
              classes of economic institutions shaping the way goods and
              services have been valued and allocated over time.
            </p>
            <p>
              Nowadays, when we hear the word auction, we perhaps think of
              traditional farm & industrial auctions, high-end art auctions, or
              repossessed assets & collateral auctions. It’s a common
              (mis)conception that auctions are meant only for the elite,
              bureaucratic, or intellectual class of society. They are not!{" "}
            </p>

            <h6>
              Auctions are for everyone, they are everywhere and they affect our
              everyday lives!
            </h6>

            <p>
              Auctions ubiquitously find applications in almost every walk of
              life and auction outcomes are also very important for us as
              taxpayers and as citizens – they decide the prices of electricity,
              emission allowances, telecom spectrums, financial assets, and
              various commodities.
            </p>
          </div>
      </section>
    </>
  );
};

export default AuctionsSecondComp;
