import { useLocation } from "react-router";
import { useEffect } from "react";

import AiSuperCharge from "./AiSuperCharge";
import Auctioning from "./Auctioning";
import AuctionsSecondComp from "./AuctionsSecondComp";
import AuthorizedPageCard from "../../components/ui/AuthorizedPageCard";
import DefiAuction from "./DefiAuction";
import Getstarted from "./Getstarted";
import GoingOnce from "./GoingOnce";
import MarketSize from "./MarketSize";
import NobelAuctions from "./NobelAuctions";
import Subscribe from "./Subscribe";
import Usecase from "./Usecase";

const Auction = () => {
  const location = useLocation()
  const activeHash = location?.hash

  useEffect(() => {
    const targetDiv = document.getElementById(activeHash?.substring(1));

    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: 'smooth' });
    }
  },[activeHash])
  
    return (
      <AuthorizedPageCard>
        <GoingOnce />
        <AuctionsSecondComp />
        <MarketSize />
        <Auctioning />
        <Usecase/>
        <DefiAuction/>
        <AiSuperCharge/>
        <NobelAuctions />
        <Getstarted />
        <Subscribe />
      </AuthorizedPageCard>
    );
  };
  
  export default Auction;